<template>
  <v-snackbar
    v-model="snackbar"
    multi-line
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
  >
    <div class="font-weight-bold text-sm-body-1">
      {{ text }}
    </div>
    <div v-for="(item, index) in list" :key="index">
      <div v-for="(message, index) in item" :key="index + 'sf'">
        {{ message }}
      </div>
    </div>
    <template v-slot:action>
      <v-btn
        class="v-snackbar__content--btn"
        small
        icon
        @click="snackbar = false"
      >
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      color: "",
      snackbar: false,
      text: "",
      list: [],
      timeout: 5000,
      x: null,
      y: "top",
    };
  },
  methods: {
    errorMessage(errors) {
      if (errors) {
        return Object.values(errors);
      }
      return [];
    },
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "snackbarModule/SET_ERROR_API" &&
        typeof mutation.payload === "string"
      ) {
        this.color = "error";
        this.snackbar = true;
        this.text = mutation.payload;
        return;
      }
      if (mutation.type === "snackbarModule/SET_ERROR_API") {
        const errorData = mutation.payload?.data;
        const errorCode = mutation.payload?.status;
        this.color = "error";
        this.snackbar = true;
        this.text = errorCode ? errorCode + " " : "";
        this.text += errorData ? errorData.detail : "";
        this.list = errorData ? this.errorMessage(errorData.errors) : "";
        return;
      }
      if (mutation.type === "snackbarModule/SET_SUCCESS_MESSAGE") {
        const sussessData = mutation.payload;
        this.color = "success";
        this.snackbar = true;
        this.text = sussessData || "";
        this.list = "";
        return;
      }
      if (mutation.type === "snackbarModule/SET_WARNING_MESSAGE") {
        const errorData = mutation.payload;
        this.color = "warning";
        this.snackbar = true;
        this.list = this.errorMessage(mutation.payload.errors);
        this.text = errorData.message || "";
        return;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-snack {
  &__wrapper {
    min-width: 304px !important;
  }
}
</style>
